.card-container {
    background-color: var(--color-theme-day-background--shade-2);
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@supports (-webkit-touch-callout: none) {
    .card-container {
        min-height: -webkit-fill-available;
    }
}
