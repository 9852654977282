.bw-logo {
    width: 155px;
    height: 172px;
    margin-left: 50%;
    margin-top: 60px;
    transform: translateX(-50%);
}

@media (max-width: 620px) {
    .bw-logo {
        width: 60px;
        height: 67px;
        margin-top: 20px;
    }
}

.logo-blue {
    fill: var(--color-brand-hooloovoo);
}

.logo-orange {
    fill: var(--color-product-blast-off);
}

.logo-purple {
    fill: var(--color-product-serene-sea);
}

.logo-red {
    fill: var(--color-product-crash-course);
}

.logo-green {
    fill: var(--color-product-terra-form);
}

.logo-yellow {
    fill: var(--color-product-new-horizon);
}
