.card {
    background-color: var(--color-theme-day-background--shade-1);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', serif;
    padding: 40px;
    max-width: 600px;
}

.body {
    display: flex;
    flex-direction: column;
    height: 75%;
    justify-content: space-between;
}

.body .title {
    color: var(--color-theme-day-text);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.016px;
    margin: 32px 0 0 0;
    padding: 0 34px;
    text-align: center;
}

.body .password-input {
    margin-top: 32px;
    padding: 0 100px;
    position: relative;
}

.body .password-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.password-input-tooltip {
    justify-content: end;
    max-width: 238px;
}

.body .main-button {
    display: flex;
    justify-content: center;
    margin: 32px 0 47px 0;
    padding: 0 62px;
}

.body .password-input .ax-input__hint:last-child {
    margin-left: 0;
}

.delimiter {
    background-color: var(--color-theme-day-background--subtle);
    height: 1px;
    width: 100%;
}

.footer {
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
}

.footer .content {
    color: var(--color-theme-day-text--subtle);
    font-weight: 400;
    margin: 8px 0 0 0;
}

.footer .title {
    color: var(--color-theme-day-text);
    font-size: inherit;
    font-weight: 700;
    line-height: 20px;
    margin-top: 30px;
    position: relative;
    text-align: left;
}

.footer .title p {
    margin: 0;
}

.main-description {
    color: var(--color-theme-day-text--subtle);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02px;
    line-height: 24px;
    margin-left: 98px;
    margin-right: 98px;
    text-align: center;
}

.main-description--long {
    color: var(--color-theme-day-text--subtle);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.02px;
    line-height: 24px;
    margin: 16px 43px 48px 43px;
    text-align: center;
}

@media (max-width: 620px) {
    .card {
        box-sizing: border-box;
        height: unset !important;
        justify-content: start;
        margin: 0 10px;
        width: 100%;
    }

    .main-description--long {
        font-size: 16px;
        letter-spacing: -0.02px;
        line-height: 24px;
        margin: 32px 0 0 0;
        text-align: center;
    }

    .logo {
        height: unset;
    }

    .body {
        height: unset;
        justify-content: start;
    }

    .body .password-input {
        margin-top: 32px;
        padding: 0 0;
    }

    .delimiter {
        margin-top: 26px;
    }

    .footer .title {
        font-size: 13px;
        line-height: 20px;
    }

    .footer .content {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
    }

    .body .main-button {
        margin: 32px 0 0 0;
        padding: 0 0;
    }

    .ax-button {
        width: 100%;
    }

    .body .title {
        font-size: 22px;
        letter-spacing: -0.02px;
        line-height: 28px;
        margin: 32px 0 0 0;
        padding: 0;

    }
}
