/* SPINNER BEGIN */

#loadSpinner {
    align-items: center;
    display: grid;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}

.spinner {
    display: block;
    height: 86px;
    width: 86px;
}

#loadSpinner .tiles,
#loadSpinner .phase-1,
#loadSpinner .phase-2 {
    animation-duration: 2.2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}

#loadSpinner.stopped .tiles,
#loadSpinner.stopped .phase-1,
#loadSpinner.stopped .phase-2 {
    animation-play-state: paused;
}

#loadSpinner.stopped .tiles {
    display: none;
}

#loadSpinner.stopped .spinner {
    animation-name: load-spinner-hide-spinner;
    animation-duration: 0.8s;
    animation-delay: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
    transform-origin: 50% 50%;
}

#loadSpinner.stopped {
    animation-delay: 0.2s;
    animation-name: load-spinner-hide;
    animation-duration: 0.8s;
    animation-iteration-count: 1;
}

#loadSpinner.hidden {
    display: none;
}

#loadSpinner .phase-1 {
    animation-name: load-spinner-animation-phase-1;
}

#loadSpinner .phase-2 {
    animation-name: load-spinner-animation-phase-2;
}

#loadSpinner .phase-1 .tile-orange {
    animation-name: load-spinner-animation-orange;
}

#loadSpinner .phase-2 .tile-orange {
    animation-name: load-spinner-animation-orange-2;
}

#loadSpinner .phase-1 .tile-yellow {
    animation-name: load-spinner-animation-yellow;
}

#loadSpinner .phase-2 .tile-yellow {
    animation-name: load-spinner-animation-yellow-2;
}

#loadSpinner .phase-1 .tile-red {
    animation-name: load-spinner-animation-red;
}

#loadSpinner .phase-2 .tile-red {
    animation-name: load-spinner-animation-red-2;
}

#loadSpinner .phase-1 .tile-blue {
    animation-name: load-spinner-animation-blue;
}

#loadSpinner .phase-2 .tile-blue {
    animation-name: load-spinner-animation-blue-2;
}

@keyframes load-spinner-hide-spinner {
    to { transform: rotate(90deg) scale(0.4); }
}

@keyframes load-spinner-hide {
    to { opacity: 0; }
}

@keyframes load-spinner-animation-orange {
    0% { transform: translate(-93.7%, 0); }
    11% { transform: translate(0, 0); }
    100% { transform: translate(0, 0); }
}

@keyframes load-spinner-animation-orange-2 {
    56% { transform: translate(0, 0); }
    67% { transform: translate(93.7%, 0); }
    100% { transform: translate(93.7%, 0); }
}

@keyframes load-spinner-animation-yellow {
    0% { transform: translate(-75.2%, 0); }
    12.5% { transform: translate(-75.2%, 0); }
    22% { transform: translate(0, 0); }
    100% { transform: translate(0, 0); }
}

@keyframes load-spinner-animation-yellow-2 {
    0% { transform: translate(-75.2%, 0); }
    12.5% { transform: translate(-75.2%, 0); }
    22% { transform: translate(0, 0); }
    67% { transform: translate(0, 0); }
    78% { transform: translate(75.2%, 0); }
    100% { transform: translate(75.2%, 0); }
}

@keyframes load-spinner-animation-red {
    0% { transform: translate(89.7%, 0); }
    22% { transform: translate(89.7%, 0); }
    33% { transform: translate(0, 0); }
    100% { transform: translate(0, 0); }
}

@keyframes load-spinner-animation-red-2 {
    0% { transform: translate(89.7%, 0); }
    22% { transform: translate(89.7%, 0); }
    33% { transform: translate(0, 0); }
    78% { transform: translate(0, 0); }
    89% { transform: translate(-89.7%, 0); }
    100% { transform: translate(-89.7%, 0); }
}

@keyframes load-spinner-animation-blue {
    0% { transform: translate(95.7%, 0); }
    33% { transform: translate(95.7%, 0); }
    44% { transform: translate(0, 0); }
    100% { transform: translate(0, 0); }
}

@keyframes load-spinner-animation-blue-2 {
    100% { transform: translate(0, 0); }
    89% { transform: translate(0, 0); }
    100% { transform: translate(-95.7%, 0); }
}

@keyframes load-spinner-animation-phase-1 {
    0% { opacity: 1; }
    49.999% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 0; }
}
@keyframes load-spinner-animation-phase-2 {
    0% { opacity: 0; }
    49.999% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 1; }
}

/* SPINNER END */
